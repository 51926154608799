
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import type { FlagCountry } from '@/definitions/shared/types'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  setup() {
    const username = ref('textmagic123')
    const firstName = ref('John')
    const lastName = ref('Doe')
    const email = ref('johndoe@gmail.com')
    const mobile = ref('+33655557663')
    const country = ref<FlagCountry>(phoneCountryVariant[0])
    const timezone = ref(utcZonesOptions[16])
    const timeFormat = ref('12-hour clock')
    const timeFormatList = ref([
      '12-hour clock',
      '24-hour clock',
    ])

    return {
      phoneCountryVariant,
      username,
      firstName,
      timeFormat,
      timeFormatList,
      timezone,
      lastName,
      email,
      mobile,
      country,
    }
  },
})
